import { Slot } from "@radix-ui/react-slot";
import { VariantProps, cva, cx } from "class-variance-authority";

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;
export const buttonVariants = cva(
  [
    "text-base font-bold text-center px-5 py-3 flex justify-center items-center",
    "[&:not(:disabled)]:transition-colors",
  ],
  {
    variants: {
      size: {
        SMALL: "h-10",
        BASIC: "h-10 from-tablet:h-12",
        LARGE: "h-10 from-tablet:h-12 from-desktop:h-16",
      },
      variant: {
        PRIMARY: "bg-primary text-secondary",
        SECONDARY: "bg-secondary text-primary",
        TERTIARY: "bg-active text-primary",
      },
      disabled: {
        true: "opacity-60 pointer-events-none cursor-not-allowed",
        false: "hover:bg-action hover:text-primary cursor-pointer",
      },
    },
  }
);

export type ButtonSizeVariant = NonNullable<ButtonVariantProps["size"]>;
export type ButtonVariant = NonNullable<ButtonVariantProps["variant"]>;

export type ButtonProps = {
  id?: string;
  size?: ButtonSizeVariant;
  variant?: ButtonVariant;
  className?: string;
  href?: string;
  onClick?: unknown;
  asChild?: boolean;
  disabled?: boolean;
} & Pick<React.ComponentProps<"button">, "type">;

export function BaseButton({
  size = "BASIC",
  variant = "PRIMARY",
  className,
  asChild,
  children,
  disabled = false,
  href,
  ...props
}: React.PropsWithChildren<ButtonProps>) {
  const Component: any = asChild ? Slot : "button";
  const url = href && !disabled ? href : undefined;

  return (
    <Component
      className={cx(buttonVariants({ size, variant, disabled }), className)}
      disabled={disabled}
      href={url}
      {...props}
    >
      {children}
    </Component>
  );
}
