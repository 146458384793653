declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

export function pushDataLayer(events: unknown[]) {
  if (typeof window === "undefined") {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(...events);
}
